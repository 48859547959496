html,
body {
  height: 80% !important;
  margin: 0;
  padding: 0;
  background: linear-gradient(
    180deg,
    hsla(210, 4%, 89%, 1) 0%,
    hsla(210, 4%, 89%, 1) 100%
  );
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bold-title {
  font-weight: bold;
  text-align: left;
  margin-bottom: 5px;
}

.text-left {
  text-align: left;
}

.form-group.required .control-label:after {
  content: "*";
  color: red;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f63939;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

/* footer */
footer {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  position: sticky;
  top: 100%;
}

.company-logo {
  /* background-color: #d3d3d3; */
  border-radius: 5px;
}

/* conference window */
.conferencePage-container {
  margin: 1rem 1.5rem;
}
iFrame {
  /* border-radius: 1.5rem; */
}

/* video call action button */
.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
}
.button-container {
  background-color: #777272;
  padding: 0.6rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.button-container > Button,
.button-container > Button:hover,
.button-container > Button:focus,
.button-container > Button:active {
  color: #777272;
  margin: auto 3px;
  border-color: #777272;
  /* margin: 12px auto; */
}

.dropdownbutton {
  color: #777272;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.dropdownbutton > Button,
.dropdownbutton > Button:hover,
.dropdownbutton > Button:focus,
.dropdownbutton > Button:active {
  color: #777272;
  border-color: #777272;
  /* margin: 12px auto; */
}

.toolbox-content-items {
  background-color: red;
}
.toolbox-content-items > div {
  background-color: #404040;
  border-radius: 50%;
}

.container_details {
  margin: 2rem 0px;
}
.container_error {
  text-align: center;
}
.container_error > h4 {
  margin-bottom: 2rem;
  font-family: "Helvetica Neue";
}
.errorimg {
  height: 500px;
  width: 100%;
  max-width: 500px;
}
.container_success {
  margin: 0 2rem;
}
.image_container {
  display: flex;
  justify-content: center;
}
.container_success .left,
.container_success .right {
  width: 100%;
  height: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 450px) {
  .container_success .right {
    display: block;
  }
}

/* .success_bottom {
  flex-direction: column-reverse;
} */

.contain {
  padding-top: 2rem;
  font-family: "Helvetica Neue";
  display: flex;
  justify-content: start;
  /* align-items: center; */
  flex-direction: column;
}
.contain > h4 {
  line-height: 1.5;
}

.container_success .side::before {
  width: 100%;
  height: 100%;
  /* position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  content: "";
  display: block; */
}
.success_bottom {
  display: flex;
  flex-direction: column-reverse;
}

.image_container img {
  height: 260px;
  /* width: 90%; */
  padding-left: 1rem;
  object-fit: contain;
  /* margin-left: 0; */

  /* max-width: 500px; */
}

@media screen and (min-width: 900px) {
  .image_container {
    display: flex;
    justify-content: start;
  }
  .container_success {
    display: flex;
  }
  .success_bottom {
    flex-direction: row;
  }
  .container_success .left,
  .container_success .right {
    width: 50%;
    /* height: 58vh; */
  }
  .container_success .left {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 900px) {
  .container_success .left {
    margin-top: 5rem;
  }
}

.container_form {
  padding: 0rem 1rem;
  width: 80%;
  background: rgba(255, 255, 255, 0.33);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.container_form_input {
  padding: 0.5rem 0;
  font-size: 18px;
}
.form-control::placeholder {
  font-size: 16px;
}
.container_button {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}
.container_button > Button {
  width: 150px;
}

@media screen and (max-width: 413px) {
  .container_form {
    width: 100%;
  }
  .form-control::placeholder {
    font-size: 14px;
  }
  .container_form_input {
    font-size: 15px;
  }
  .container_button > Button {
    font-size: 14px;
  }
}

/* Loading */
#loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

#loading-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #999;
  width: 100px;
  height: 30px;
  margin: -7px 0 0 -45px;
  text-align: center;
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 20px;
}

#loading-content {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 170px;
  height: 170px;
  margin: -85px 0 0 -85px;
  border: 3px solid #f00;
}

#loading-content:after {
  content: "";
  position: absolute;
  border: 3px solid #0f0;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
}

#loading-content:before {
  content: "";
  position: absolute;
  border: 3px solid #00f;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
}

#loading-content {
  border: 3px solid transparent;
  border-top-color: #4d658d;
  border-bottom-color: #4d658d;
  border-radius: 50%;
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
}

#loading-content:before {
  border: 3px solid transparent;
  border-top-color: #d4cc6a;
  border-bottom-color: #d4cc6a;
  border-radius: 50%;
  -webkit-animation: loader 3s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 3s linear infinite;
}

#loading-content:after {
  border: 3px solid transparent;
  border-top-color: #84417c;
  border-bottom-color: #84417c;
  border-radius: 50%;
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
}

@-webkit-keyframes loaders {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#content-wrapper {
  color: #fff;
  position: fixed;
  left: 0;
  top: 20px;
  width: 100%;
  height: 100%;
}

#header {
  width: 800px;
  margin: 0 auto;
  text-align: center;
  height: 100px;
  background-color: #666;
}

#content {
  width: 800px;
  height: 1000px;
  margin: 0 auto;
  text-align: center;
  background-color: #888;
}

.center-screen {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  text-align: center;
  /* min-height: 75vh; */
  position: relative;
  top: 40vh;
}

.loader > span {
  width: 15px;
  height: 15px;
  display: block;
  background: lightskyblue;
  border-radius: 50%;
  position: relative;
  margin: 0 5px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot-1 {
  animation: anim 1s linear 0s infinite;
}

.dot-2 {
  animation: anim 1s linear 0.25s infinite;
}

.dot-3 {
  animation: anim 1s linear 0.5s infinite;
}

.dot-4 {
  animation: anim 1s linear 0.75s infinite;
}

@keyframes anim {
  0% {
    top: 0;
  }

  50% {
    top: 15px;
  }

  100% {
    top: 0;
  }
}

.jitsi_header {
  display: flex;
  margin-bottom: 10px;
  /* text-align: center; */
  align-items: center;
  position: relative;
  height: 10vh;
}

.jitsi_header_item {
  margin: 0px 10px;
  font-weight: bold;
  text-transform: uppercase;
}
.jitsi-header_logo{
  position: absolute;
  left: 0px;
  top: 0px;
  height: fit-content;
}
