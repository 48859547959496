.company-logo {
  max-width: 100vw;
  max-height: 10vh;
  margin-left: 5px;
  /* max-height: 50px; Adjust the max height as needed */
}

.meeting-header {
  color: lightcoral;
  /* justify-content: center; */
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  font-size: x-large;
  text-transform: capitalize;
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  overflow: hidden; /* Hides any content that overflows the box */
  text-overflow: ellipsis;
}
.contentStyle {
  text-align: center;
  height: 85vh;
  color: #fff;
  background-color: white;
  margin: "5px";
  border-radius: 8;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 10vh;
  padding-inline: 20;
  background-color: white;
  margin: 5px;
  border-radius: 8;
}
.siderClass {
  text-align: left;
  height: 85vh;
  color: #fff;
  background-color: white;
  margin: 5px;
  border-radius: 8;
}
.text-container {
  width: 200px; /* Set your desired width */
  /* padding: 10px;  */
  overflow-wrap: break-word; /* This property allows text to break onto the next line */
}
